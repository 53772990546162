@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "../../_variables";
@import "../../fiduc/loaders";
//@import "/node_modules/bootstrap/scss/mixins.scss";

CookiesVisualFs {
	.policy-link {
		color: black;
		font-weight: 600;
		text-decoration: none;
	}

	.policy-title {
		font-size:16pt;
		font-weight: 900;
		margin: 2pt;
		font-family:"PP Neue Machina", "Helvetica Neue", Helvetica;
	}

	.policy-container.hidden {
		opacity: 0 !important;
		pointer-events: none !important;
		width:1px;
		height:1px;
		//display: none;
	}

	.policy-container.visible {
		opacity: 1;
		/*display: flex;*/
		bottom: 0px;
	}

	.policy-container {
		left:0;
		top:0;
		right:0;
		bottom:0;
		position: fixed;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		width: 100vw;
		height: 100vh;
		max-width: 100vw;
		max-height: 100vh;
		min-width: 100vw;
		min-height: 100vh;
		background-color: rgba(0,0,0,.5);
		justify-content: center;
		align-items: center;
		transition: opacity .400s ease;
		z-index: 9999;
		@media (max-width: 720px) {
			padding: 12pt 12pt;
		}
	}

	.policy-content {
		border-radius: 4pt;
		background-color: white;
		width: 50%;
		@media (max-width: 720px) {
			width: 100%;
		}
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border: solid 1px lightgray;
	    box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
		padding: 12pt 12pt;
	}

	.policy-message-container {
		display: flex;
		flex-direction: row;
		margin: 12pt 24pt;
	}

	.policy-checkbox-container INPUT[type=checkbox] {
		align-self: flex-start;
		justify-self: flex-start;
	}

	.policy-checkbox-container {
		margin:2px 12px;
	}

	INPUT[type=checkbox] {
		width: 1.25rem;
		height: 1.25rem;
		/* margin-top: 0.25em; */
		vertical-align: top;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		border: 1px solid rgba(0, 0, 0, 0.25);
		appearance: none;
		-webkit-print-color-adjust: exact;
		color-adjust: exact;
		transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		background-color: #27E861 !important;
		transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}


	INPUT[type=checkbox]:checked {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
	}

	.policy-loading-dots-container {
		margin-bottom:10pt;
	}
	.policy-buttons {
		margin: 6pt;
		width: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-direction: row;
	}

	.button {
		width: 10rem;
		padding: 8pt 12pt;
		font-size: 12pt;
		white-space: nowrap;
		transition: background-color 300ms linear 0s, color 300ms linear 0s, transform 100ms linear 0s;
		font-weight: 600;
		display: flex;
		justify-content: center;
		align-items: center;
		border:solid 1pt transparent;

		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
		cursor: pointer;
	}

	.button.primary {
		background-color: $fiduc-button-active;
		color:black;
	}

	.button:hover {
		transform:scale(1.050)
	}

	.button.primary:hover {
		background-color: $fiduc-button-active;
		color:black;
	}

	.button.disabled {
		pointer-events: none;
		opacity: .2;
		cursor:pointer !important;
	}

	.button.secondary {
		background-color: white;
		color:black;
		border:solid 1pt black;
	}
	.button.secondary:hover {
		background-color: $fiduc-danger;
		color:white;
	}

	.policy-link {
		font-weight: normal;
		color: $fiduc-blue;
		text-decoration: underline !important;
	}

}
