$size: 8px;
$dot-spacing : $size * 2;

$dot-themes: (
  light: (
    color-active: $fiduc-gray,
    color-inactive: $fiduc-success,
	color-off: $fiduc-gray
  ),
  dark: (
    color-active: $fiduc-light-gray,
    color-inactive: $fiduc-black,
	color-off: $fiduc-black
  )
);

@each $state, $theme in $dot-themes {

	/*
	* ==============================================
	* Dot Flashing
	* ==============================================
	*/
	.dot-flashing-no-anim.dot-flashing-#{$state},
	.dot-flashing-no-anim.dot-flashing-#{$state}::before,
	.dot-flashing-no-anim.dot-flashing-#{$state}::after
	{
		animation-duration: 0s;
		background-color: map-get($theme, color-off);
		animation: unset !important;
	}

	.dot-flashing-#{$state} {
		position: relative;
		width: $size;
		left:0px;
		height: $size;
		border-radius: $size / 2;
		background-color: map-get($theme, color-active);
		color: map-get($theme, color-active);
		animation: dotFlashing-#{$state} 1s infinite linear alternate;
		animation-delay: .5s;
	}

	.dot-flashing-#{$state}::before, .dot-flashing-#{$state}::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: 0;
	}

	.dot-flashing-#{$state}::before {
		left: -$dot-spacing;
		width: $size;
		height: $size;
		border-radius: $size / 2;
		background-color: map-get($theme, color-inactive);
		color: map-get($theme, color-inactive);
		animation: dotFlashing-#{$state} 1s infinite alternate;
		animation-delay: 0s;
	}

	.dot-flashing-#{$state}::after {
		left: $dot-spacing;
		width: $size;
		height: $size;
		border-radius: $size / 2;
		background-color:  map-get($theme, color-active);
		color: map-get($theme, color-active);
		animation: dotFlashing-#{$state} 1s infinite alternate;
		animation-delay: 1s;
	}

	@keyframes dotFlashing-#{$state} {
		0% {
			background-color: map-get($theme, color-inactive);
		}
		50%,
		100% {
			background-color: map-get($theme, color-active);
		}
	}


}
